import { ReactElement, useState } from "react";
import * as styles from "./SpecialOffer.module.scss";
import CtaLink from "../../Shared/CtaLink";
import { closeIcon } from "../../Icons";

interface SpecialOfferProps {
    widgetOffer: GatsbyTypes.strapi_ComponentWidgetsSpecialOffer;
    generalOffer: GatsbyTypes.Maybe<GatsbyTypes.strapi_GeneralSpecialOffer>;
}

export default function SpecialOffer(props: SpecialOfferProps): ReactElement {
    const specialOffer = props.widgetOffer.useGeneral && props.generalOffer ? props.generalOffer : props.widgetOffer;
    const [show, setShow] = useState(true);

    function hide() {
        setShow(false);
    }

    return (
        <>
            {show && (
                <div className={styles.specialOffer}>
                    <div className={`mainBox ${styles.inner}`}>
                        <span>{specialOffer.base?.heading}</span>
                        {specialOffer.cta && <CtaLink cta={specialOffer.cta} />}
                    </div>
                </div>
            )}
        </>
    );
}
