import { ReactElement, useState, useEffect } from "react";
import { graphql } from "gatsby";
import { useDependency } from "../../contexts/DependencyContext";
import Helmet from "../../components/Helmet";
import SpecialOffer from "../../components/Widgets/SpecialOffer";
import Product from "../../components/Widgets/Products/Product";
import Filters, { FilterModel } from "../../components/Shared/Filters";
import { FilterItemModel } from "../../components/Shared/Filters/FilterItem";
import CtaLink from "../../components/Shared/CtaLink";
import Loader from "../../components/Shared/Loader";
import { ProductsService } from "../../services/http/ProductsService";
import { TelemetryService } from "../../services/telemetry/TelemetryService";
import { headerImage } from "../../components/Icons";
import * as styles from "./Products.module.scss";

interface IProductsProps {
    data: GatsbyTypes.ProductsPageQuery;
}

export default function ProductsPage({ data }: IProductsProps): ReactElement {
    const [searching, setSearching] = useState(false);
    const [filteredProducts, setFilteredProducts] = useState(data.strapi.productsPage?.products ?? []);
    const [conditions, setConditions] = useState<any[] | undefined>();
    const [filterModels, setFilterModels] = useState<FilterModel[]>([]);
    const productsService = useDependency(ProductsService);
    const telemetryService = useDependency(TelemetryService);

    useEffect(() => {
        const productVendorsFilters = (data.strapi?.productVendors as GatsbyTypes.strapi_ProductVendor[]).map(x => new FilterItemModel(x.id, x.name, x.iconSvg));
        const productCategoriesFilters = (data.strapi?.productCategories as GatsbyTypes.strapi_ProductCategories[]).map(x => new FilterItemModel(x.id, x.name, x.iconSvg));

        setFilterModels([
            new FilterModel("product_category", data.strapi.productsPage?.categoriesFilterLabel, productCategoriesFilters, "category"),
            new FilterModel("product_vendor", data.strapi.productsPage?.vendorsFilterLabel, productVendorsFilters, "vendor"),
        ]);
    }, []);

    useEffect(() => {
        if (!conditions) return;

        setSearching(true);

        const execute = async () => {
            try {
                const results = await productsService.getAsync(conditions);
                setFilteredProducts(results);
            } catch (error) {
                console.error(error);
            } finally {
                setSearching(false);
            }
        };

        execute();
    }, [conditions]);

    function handleFiltersChange(searchText: string, filters: Map<string, FilterItemModel[]>) {
        const newConditions: any[] = [];
        if (searchText !== "") newConditions.push({ name_contains: searchText });
        filters.forEach((values, key) => newConditions.push({ [`${key}.id`]: values.map(x => x.id) }));
        setConditions(newConditions);
    }

    return (
        <>
            <Helmet title={"Products"} description={""} />

            <section className={styles.ourProducts}>
                <div className="headerContainer">
                    <div className="mainBox">
                        <h1>{data.strapi.productsPage?.base?.heading}</h1>
                    </div>
                    <div className="headerImage">{headerImage}</div>
                </div>

                {(data.strapi.productsPage?.specialOffer || data.strapi.generalSpecialOffer) && (
                    <SpecialOffer
                        widgetOffer={data.strapi.productsPage?.specialOffer as GatsbyTypes.strapi_ComponentWidgetsSpecialOffer}
                        generalOffer={data.strapi.generalSpecialOffer as GatsbyTypes.strapi_GeneralSpecialOffer}
                    />
                )}

                <div className="mainBox">
                    <div className={styles.inner}>
                        <Filters
                            content={data.strapi.productsPage?.filters as GatsbyTypes.strapi_ComponentSharedFiltersContent}
                            filterModels={filterModels}
                            onChange={handleFiltersChange}
                            onFilterChange={(filterId, value) => telemetryService.productsSearch({ [filterId]: value })}
                        />

                        {searching ? (
                            <div className={styles.products}>
                                <Loader loadingText={data.strapi.productsPage?.filters?.loadingText} />
                            </div>
                        ) : (
                            <div className={styles.products}>
                                {filteredProducts.length > 0 ? (
                                    filteredProducts.map(x => (
                                        <Product
                                            key={x?.id}
                                            product={x as GatsbyTypes.strapi_Product}
                                            linkText={data.strapi.productsPage?.productLinkText ?? ""}
                                            linkTo="products"
                                            addButtonText={data.strapi.productsPage?.addToCartButtonText}
                                            className={styles.productItem}
                                        />
                                    ))
                                ) : (
                                    <div className={styles.noProducts}>{data.strapi.productsPage?.filters?.noDataText ?? ""}</div>
                                )}

                                {data.strapi.productsPage?.support && (
                                    <div className={styles.help}>
                                        <h3>{data.strapi.productsPage.support.base?.heading ?? ""}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: data.strapi.productsPage.support.base?.paragraph ?? "" }}></div>
                                        {data.strapi.productsPage.support.cta && <CtaLink cta={data.strapi.productsPage.support.cta as GatsbyTypes.strapi_ComponentSharedCtaContent} />}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export const query = graphql`
    query ProductsPage {
        strapi {
            productsPage {
                base {
                    heading
                    paragraph
                }
                filters {
                    headingText
                    searchLabel
                    applyButtonText
                    loadingText
                    noDataText
                }
                specialOffer {
                    base {
                        heading
                        paragraph
                    }
                    cta {
                        text
                        link
                        external
                    }
                    useGeneral
                }
                support {
                    base {
                        heading
                        paragraph
                    }
                    cta {
                        link
                        text
                        external
                    }
                }
                products(sort: "name") {
                    id
                    name
                    description
                    color
                    images {
                        url
                        url_sharp {
                            childrenImageSharp {
                                gatsbyImageData(quality: 60)
                            }
                        }
                    }
                    licensesTypes {
                        ... on strapi_ComponentProductLicenseType {
                            id
                            shopifyId
                            license_type {
                                id
                                name
                                description
                                pluralQtyText
                                singularQtyText
                                isSubscription
                            }
                            tieredPricing {
                                quantity
                                price
                            }
                        }
                    }
                }
                addToCartButtonText
                productLinkText
                categoriesFilterLabel
                vendorsFilterLabel
            }
            productVendors {
                id
                name
                iconSvg
            }
            productCategories {
                id
                name
                iconSvg
            }
            generalSpecialOffer {
                base {
                    heading
                    paragraph
                }
                cta {
                    link
                    text
                    external
                }
            }
        }
    }
`;
