import { ContextCollection } from "../../contexts/DependencyContext";
import { StrapiClient } from "./clients/StrapiClient";
import QueryString from "qs";

export class ProductsService {
    private readonly url: string;

    constructor(private readonly apiClient: StrapiClient) {
        this.url = "products";
    }

    async getAsync(conditions: any[]): Promise<GatsbyTypes.strapi_Product[]> {
        return await this.apiClient.get<GatsbyTypes.strapi_Product[]>(
            `${this.url}?${QueryString.stringify({
                _where: conditions,
                _sort: "name",
            })}`
        );
    }
}

ContextCollection.registerSingleton(ProductsService, [StrapiClient]);
